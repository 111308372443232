<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)" ref="result_by_subject_form" class="repeater-form"
        @submit="showProcessedResults">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Class") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select v-model="classes_id" class="form-control" name="classes_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">
                    {{ cls.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Version") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('version')" rules="required">
                <select v-model="version_id" class="form-control" name="version_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(ver, key) in versions" :key="key" :value="ver.id">
                    {{ ver.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Shift") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('shift')" rules="required">
                <select v-model="shift_id" class="form-control" name="shift_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(shift, key) in shifts" :key="key" :value="shift.id">
                    {{ shift.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Wing") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wing')" rules="required">
                <select v-model="wing_id" class="form-control" name="wing_id" :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(wing, key) in wings" :key="key" :value="wing.id">
                    {{ wing.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Student group") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student group')" rules="required">
                <select v-model="student_group_id" class="form-control" name="student_group_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(grp, key) in student_groups" :key="key" :value="grp.id">
                    {{ grp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Academic Year") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('academic year')" rules="required">
                <select v-model="academic_year_id" class="form-control" name="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(year, key) in academic_years" :key="key" :value="year.id">
                    {{ year.year }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Exam type") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('exam type')" rules="required">
                <select v-model="exam_type_id" class="form-control" name="exam_type_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(eType, key) in active_exam_types" :key="key" :value="eType.id">
                    {{ eType.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Subject") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('subject')" rules="required">
                <select v-model="subject_id" class="form-control" name="subject_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(subject, key) in subjects" :key="key" :value="subject.id">
                    {{ subject.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" class="mt-2 pt-1">
            <b-button variant="outline-primary" type="submit">
              {{ $t("Find") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx :data="getData" :filename="'filename'" :sheetname="'subject_results'">
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'subject_wise_result'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="downloadResultBySubject()">
        <b>PDF</b>
      </button>
    </div>

    <b-table-simple hover small caption-top responsive>
      <caption>
        Result by subjects
      </caption>
      <colgroup>
        <col />
        <col />
      </colgroup>
      <colgroup>
        <col />
        <col />
        <col />
      </colgroup>
      <colgroup>
        <col />
        <col />
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th class="text-center" :colspan="1">Cadet No.</b-th>
          <b-th class="text-left" :colspan="1">Name</b-th>
          <template v-for="(md, key) in mark_distributions">
            <b-th class="text-center" :key="key">{{
                md.name + " (" + md.total_mark + ")"
            }}</b-th>
            <b-th class="text-center" :key="key + 'p'">{{ md.mark_percent_add }} %</b-th>
          </template>
          <b-th class="text-center">Status</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(mark_array, result_key) in results" :key="result_key">
          <b-td class="text-center">{{ mark_array[0].student.cadet_no }}</b-td>
          <b-td class="text-left">{{ studentNameByID(mark_array[0].student_id) }}</b-td>
          <template v-for="(md, md_key) in mark_distributions">
            <b-td :key="md_key" class="text-center">{{
                findMark(mark_array, md) ? findMark(mark_array, md).total_mark : null
            }}</b-td>
            <b-td :key="md_key + 'p'" class="text-center">{{
                findMark(mark_array, md) ?  parseFloat(findMark(mark_array, md).converted_mark).toFixed(1) : null
            }}</b-td>
          </template>
          <b-td :variant="isPass(mark_array) ? 'success' : 'danger'" class="text-center">{{
              (isPass(mark_array) == true) ? "Passed" : "Failed"
          }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td colspan="7" class="text-right">
            Total: <b>{{ Object.keys(results).length }}</b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTfoot,
  BTh,
  BTbody,
} from "bootstrap-vue";
import { required } from "@validations";
import download from "downloadjs";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from "vue-json-to-csv";
import Vue from "vue";
Vue.use(VueExcelXlsx);
import store from "@/store/index";
import apiCall from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import constants from "@core/utils/constants";
import moment from "moment";
export default {
  name: "ResultBySubject",
  components: {
    BCardCode,
    BFormGroup,
    BThead,
    BTr,
    BTd,
    BTfoot,
    BButton,
    BCardText,
    BTh,
    BTbody,
    BForm,
    BRow,
    BCol,
    BTableSimple,
    ValidationProvider,
    ValidationObserver,
    VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      pageLength: 50,
      searchTerm: "",
      classes_id: null,
      // version_id: null,
      // shift_id: null,
      wing_id: null,
      student_group_id: null,
      academic_year_id: null,
      exam_type_id: null,
      subject_id: null,
      classes: [],
      // versions: [],
      // shifts: [],
      wings: [],
      student_groups: [],
      required,
      dir: false,
      results: [],
      mark_distributions: [],
      grades: [],
    };
  },
  methods: {
    showProcessedResults() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData(this.$refs.result_by_subject_form);
          apiCall
            .post("/result/by/subject", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.results = response.data.data.marks;
                this.results = this.getSortedList(this.results);

                var obj = response.data.data.grade_types[0];
                this.grades = JSON.parse(obj.grades);
                this.mark_distributions = response.data.data.mark_distributions;
              } else this.$toaster.error(response.data.message);
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
            });
        } else {
          this.results = [];
          this.mark_distributions = [];
        }
      });
    },

    getSortedList(resultArray) {
      var sortedArray = [];
      var keys = Object.keys(resultArray);
      for (var i = 0; i < keys.length; i++) {
        sortedArray.push(resultArray[keys[i]]);
      }
      sortedArray.sort(this.compare);
      return sortedArray;
    },

    compare(a, b) {
      if (a[0].student.cadet_no < b[0].student.cadet_no) {
        return -1;
      }
      if (a[0].student.cadet_no > b[0].student.cadet_no) {
        return 1;
      }
      return 0;
    },

    getGradeName(mark) {
      for (var i = 0; i < this.grades.length; i++) {
        var values = Object.values(this.grades[i]);
        if (mark >= values[1] && mark <= values[2]) {
          return values[4];
        }
      }
      return "NA";
    },

    /*
     * method for find mark
     * */
    findMark(mk, md) {
      let mark = mk.find((item) => item.mark_distribution_id === md.id);
      if (mark) {
        return mark;
      }
      else {
        return null;
      }
    },
    getCadetNo(mark) {
      let data = mark.find((item) => item.student != null);

      if (data != null) {
        return data.student.cadet_no;
      }
      else {
        return null;
      }
    },
    isPass(mark_array) {
      var result = mark_array.length == mark_array.filter((item) => item.is_passed == 1).length;
      return result;
    },
    /*method for get all classes*/
    async getClasses() {
      await apiCall
        .get("/get/active/class/with/v/s/sg/wings")
        .then((response) => {
          this.classes = response.data;
        })
        .catch(() => {
          this.classes = [];
        });
    },
    jsonDecode(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return [];
      }
    },
    downloadResultBySubject() {
      let data = new FormData(this.$refs.result_by_subject_form);

      apiCall
        .post("/generate/subject/result/table/export/pdf", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "results_by_subject.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    ...mapGetters([
      "academic_years",
      "active_exam_types",
      "subjects",
      "students",
      "studentIdByID",
      "studentNameByID",
      "cadetIdByID",
    ]),
    active_exam_types() {
      return this.$store.getters.active_exam_types;
    },
    subjects() {
      return this.$store.getters.subjects.filter((item) => {
        if (
          item.classes_id === this.classes_id &&
          //item.version_id === this.version_id &&
          (
            item.student_group_id === this.student_group_id ||
            parseInt(item.student_group_id) === parseInt(constants.COMMON_GROUP_ID)
          )
        )
          return item;
      });
    },
    getData() {
      // return this.results.map(item => ({
      //     CadetNo: item.student_id ? this.cadetIdByID(item.student_id): 'TBD',
      //     Name: item.student_id ? this.studentNameByID(item.student_id) :'TBD',
      //     Marks: this.getDesiredObject(item.results)}));
      return Object();
    },
  },
  created() {
    this.getClasses();
    if (this.active_exam_types.length < 1) this.$store.dispatch("GET_ACTIVE_EXAM_TYPE");
    if (this.academic_years.length < 1)
      this.$store.dispatch("GET_ALL_ACADEMIC_YEAR");
    if (this.subjects.length < 1) this.$store.dispatch("GET_ALL_SUBJECT");
    if (this.students.length < 1) this.$store.dispatch("GET_ALL_STUDENT");
  },
  watch: {
    classes_id() {
      if (this.classes_id) {
        // this.versions = [];
        // this.shifts = [];
        this.wings = [];
        this.student_groups = [];
        // this.version_id = null;
        // this.shift_id = null;
        this.wing_id = null;
        this.student_group_id = null;
        let cls = this.classes.find((item) => item.id === this.classes_id);
        if (cls) {
          // this.versions = cls.versions;
          // this.shifts = cls.shifts;
          this.wings = cls.wings;
          this.student_groups = cls.student_groups;
        }
      } else {
        // this.versions = [];
        // this.version_id = null;
        // this.shifts = [];
        // this.shift_id = null;
        this.wings = [];
        this.wing_id = null;
        this.student_group_id = null;
        this.student_groups = [];
      }
    },
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>